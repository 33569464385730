<template lang="pug">
om-modal.change-history(
  name="change-history"
  width="640px"
  @beforeOpen="beforeOpen"
  headerBorder
  :class="{ 'change-history-hu': getLocale === 'hu' }"
)
  template(#modal-header)
    .row
      .col
        om-heading.d-flex(h5)
          span.campaign-name.mr-2 {{ campaignName }}
          span {{ $t('changeHistory.title') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('change-history')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(#modal-body)
    .loading(v-if="changeLogsLoading")
      img(:src="require('@/assets/editor/svg/loader.svg')")
    template(v-else)
      .no-history.d-flex.align-items-center.justify-content-center.flex-column(
        v-if="changeLogs.length === 0"
      )
        img(src="@/assets/empty-state/monk-magnifying.svg")
        .empty-state-text.mt-4 {{ $t('changeHistory.noHistory') }}
      .table.brand-table.col-12.px-0(v-else)
        .thead.mb-0
          .tr.justify-content-start
            .th(v-for="(column, columnIndex) in columns" :class="column")
              span.column-header.justify-content-start.pb-2 {{ $t(`changeHistory.${column}`) }}
        .tbody.mb-0
          .tr.brand-table-tr.align-items-center.justify-content-start(
            v-for="(changeLog, index) in changeLogs"
            :key="index"
          )
            .td.brand-table-td.user.d-flex.flex-column.align-items-start.justify-content-center
              span {{ changeLog.superAdminName || changeLog.userName }}
              span.email {{ changeLog.superAdminEmail || changeLog.userEmail }}
            .td.brand-table-td.change.d-flex.align-items-center.justify-content-start
              span {{ getChange(changeLog) }}
            .td.brand-table-td.date.d-flex.flex-column.align-items-start.justify-content-center
              om-tooltip(v-if="lessThan24hoursAgo(changeLog.changeDate)")
                .exact-date {{ getFullDate(changeLog.changeDate) }}
                template(slot="trigger")
                  span {{ getFromNow(changeLog.changeDate) }}
              template(v-else)
                span {{ getDate(changeLog.changeDate) }}
                span {{ getTime(changeLog.changeDate) }}
</template>

<script>
  import GET_CAMPAIGN_CHANGE_LOG from '@/graphql/GetCampaignChangeLogs.gql';
  import moment from 'moment';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        campaignId: null,
        campaignName: '',
        changeLogs: [],
        changeLogsLoading: true,
        columns: ['user', 'change', 'date'],
      };
    },

    computed: {
      ...mapGetters(['getLocale']),
    },

    methods: {
      beforeOpen(event) {
        this.campaignId = event.params.campaignId;
        this.campaignName = event.params.campaignName;

        this.getCampaignChangeLogs();
      },

      async getCampaignChangeLogs() {
        this.changeLogsLoading = true;

        const {
          data: { changeLogs },
        } = await this.$apollo.query({
          query: GET_CAMPAIGN_CHANGE_LOG,
          variables: {
            campaignId: this.campaignId,
          },
        });

        this.changeLogs = changeLogs;
        this.changeLogsLoading = false;
      },
      toCamelCase(type) {
        return type
          .toLowerCase()
          .split('_')
          .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
          .join('');
      },
      getChange(changeLog) {
        const changeType = this.toCamelCase(changeLog.changeType);
        return this.$t(`changeHistory.changeTypes.${changeType}`, {
          variantName: changeLog.variantName,
        });
      },
      getDate(date) {
        return moment(date).format('MM/DD/YYYY');
      },
      getTime(date) {
        return moment(date).format('HH:mm');
      },
      getFromNow(date) {
        return moment(date).fromNow();
      },
      lessThan24hoursAgo(date) {
        return moment().diff(moment(date), 'hours') < 24;
      },
      getFullDate(date) {
        return moment(date).format('MM/DD/YYYY HH:mm');
      },
    },
  };
</script>

<style lang="sass" scoped>

  .change-history
    &.change-history-hu
      .campaign-name
        max-width: 60%
    span
      display: block
    .campaign-name
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      flex-shrink: 1
      flex-grow: 0
      max-width: 70%
      font-size: 1.125rem
      line-height: 1.3
      font-weight: bold
    ::v-deep
      .brand-modal
        max-height: 80vh
        .brand-modal-header,
        .brand-modal-body
          padding: 24px 40px

        .brand-modal-body
          max-height: calc(80vh - 73px)
          overflow-y: scroll

        .brand-modal-footer
          padding: 0px

    .loading
      height: 81px
      position: relative
      display: flex
      align-items: center
      justify-content: center
      z-index: 10
      font: normal normal normal 0.875rem/1 FontAwesome
      -animation: spin .7s infinite linear
      -moz-animation: spin .7s infinite linear
      -ms-animation: spin .7s infinite linear
      -webkit-animation: spin .7s infinite linear


  .brand-table
    .thead .tr
      .th
        &.user
          flex: 0 0 180px
          margin-right: 20px
        &.change
          flex: 0 0 260px
          margin-right: 20px
        &.date
          fflex: 0 0 80px

        .column-header
          font-size: 14px
          line-height: 20px
          font-weight: 700

  .tbody .tr.brand-table-tr
    flex-wrap: nowrap
    min-height: unset
    .td
      font-size: 12px
      font-weight: 400
      line-height: 20px
      span
        max-width: 100%
        overflow: hidden
      &.user
        flex: 0 0 200px
        padding-right: 20px
        max-width: 200px
        .email
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          max-width: 100%
      &.change
        flex: 0 0 280px
        padding-right: 20px
        max-width: 280px
      &.date
        flex: 0 0 85px
        padding-right: 15px
        max-width: 85px
      span
        text-align: left
</style>
